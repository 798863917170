import React, {Component} from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Main from './Main';
import {BrowserRouter as Router} from 'react-router-dom'

class App extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
    <React.Fragment>
      <Header />
      <Main />
      <Footer />
    </React.Fragment>
    );
  } 
}

export default App;