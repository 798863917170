import React from 'react'
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';

///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const Section = ({title,subTitle}) => (
	<section className="section-five" id="section-five">
		<div className="container">
			<header role="title-page" className="text-center">
				<h2>{title}</h2>
				<h4>{subTitle}</h4>
			</header>
		</div>
    </section>
)
export default Section;