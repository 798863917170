import React, {Component} from 'react'
import Section from '../../components/Section'

class QRCodeDesignerTool extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<main role="main">
					<Section title="QRCode Generator Tool" subTitle="Easily create multiple static QR Codes, integrated into your application, with choices of branded or black and white QR Codes."/>
					<section className="content-wrap">
						<div className="container">
							<div className="row">
								<div className="col-md-7 col-lg-9 col-sm-12">
									<div className="qrcode-generator-form-wrap">
										
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</React.Fragment>
		)
	}
}
export default QRCodeDesignerTool;