import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import PrivacyBreadcrumbsSection from '../components/TermsBreadcrumbsSection'

class Terms extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"TopReal | Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"} </title>
					<meta property="og:title" content="TopReal | Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam" />
					<meta property="og:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta name="twitter:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<PrivacyBreadcrumbsSection />
			</React.Fragment>
		)
	}
}
export default Terms;