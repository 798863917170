import React from 'react'
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom'
import { translate } from "react-i18next"

///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const Header = ({t,props}) => (
	<header className="header-area">
		<div style={{ height: "75px"}}>
			<Navbar collapseOnSelect expand="lg" bg="navbar" variant="dark" fixed="top">
				<Navbar.Brand href="/"><img src="images/logo.png" alt="TopAuto" width="102" height="49" /></Navbar.Brand>
				<Navbar.Toggle aria-controls="responsive-navbar-nav" />
				<Navbar.Collapse id="responsive-navbar-nav">
				<Nav className="mr-auto">
					<Nav.Link as={Link} to="/">{t('home')}</Nav.Link>
					<Nav.Link as={Link} to="/solutions">{t('solutions')}</Nav.Link>
					<Nav.Link as={Link} to="/news">{t('news')}</Nav.Link>
					<Nav.Link as={Link} to="/jobs">{t('jobs')}</Nav.Link>
					<Nav.Link as={Link} to="/about">{t('about_us')}</Nav.Link>
					<Nav.Link as={Link} to="/contact">{t('contact')}</Nav.Link>
					<NavDropdown title={t('support')} id="collasible-nav-dropdown">
						<NavDropdown.Item as={Link} to="/helps">{t('help')}</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/faq">FAQ</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/terms">{t('terms')}</NavDropdown.Item>
						<NavDropdown.Item as={Link} to="/privacy">{t('privacy')}</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item as={Link} to="/about">{t('about_us')}</NavDropdown.Item>
					</NavDropdown>
				</Nav>
				<Nav>
					<NavDropdown title="Vietnamese" id="collasible-nav-dropdown">
						<NavDropdown.Item href="#action/3.1">{t('vietnamese')}</NavDropdown.Item>
						<NavDropdown.Item href="#action/3.1">{t('english')}</NavDropdown.Item>
					</NavDropdown>
	    		</Nav>
	  			</Navbar.Collapse>
	  		</Navbar>
  		</div>
	</header>
)
export default translate("translations")(Header);