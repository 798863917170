import React, {Component} from 'react'
import Section from '../components/Section'

class Features extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<main role="main">
					<Section title="All in One Fundamental Services for building Apps/Web" subTitle="Skeleton is a business core services for building user management, shorten link, phones, sms, notifications, email..."/>
				</main>
			</React.Fragment>
		)
	}
}
export default Features;