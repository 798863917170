import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom'
import i18n from "./i18n/index";
import { I18nextProvider } from "react-i18next";

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<I18nextProvider i18n={i18n}>
		<Router>
			<App />
		</Router>
	</I18nextProvider>
	, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
