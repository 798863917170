import React from 'react'
import { translate } from "react-i18next"

///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const ContactBreadcrumbsSection = ({t,title,subTitle}) => (
	<section className="section-breadcrumbs">
		<div className="container">
			<header role="title-page" className="text-center">
				<h2>{t(title)}</h2>
				<h4>{t(subTitle)}</h4>
			</header>
		</div>
    </section>
)
export default translate("translations")(ContactBreadcrumbsSection);