import React from 'react';
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import ComingSoonBreadcrumbsSection from '../components/ComingSoonBreadcrumbsSection'

const AboutUs = ({t}) => (
	<React.Fragment>
		<Helmet>
			{/*facebook*/}
			<title> {"Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"} </title>
			<meta property="og:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam" />
			<meta property="og:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
			<meta property="og:url" content="http://topreal.vn"/>
			{/*Twtiter*/}
			<meta name="twitter:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta name="twitter:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
			<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
			<meta name="twitter:card" content="summary_large_image"/>
		</Helmet>
		<ComingSoonBreadcrumbsSection />
		<div className="agents">
			<div className="container">
				<div className="row">
					<div className="col-12">
					</div>
				</div>
			</div>
		</div>
	</React.Fragment>
)

export default translate("translations")(AboutUs)