import React, {Component} from 'react'
class Pricing extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<main role="main">
					<section className="section-contact" id="section-six">
						<div className="container">
							<header role="title-page" className="text-center">
								<h2>The website is underconstruction.</h2>
								<h4></h4>
							</header>
							<div className="clearfix"></div>
						</div>
					</section>
				</main>
			</React.Fragment>
		)
	}
}
export default Pricing;