import React, {Component} from 'react';
import Section from '../components/Section';
import { Link } from 'react-router-dom'

class FreeTools extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<main role="main">
					<Section title="Skeleton Free Tools" subTitle="Use our free tools to verify phone numbers, email addresses, shorten link and much more."/>
					<section className="content-wrap">
						<div className="container">
							<div className="row">
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
										<Link className="fa-wrap fa-wrap-2x" to="/free-tools/qr-code-generator"><i className="fas fa-qrcode"></i></Link>

										{/*
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="fas fa-qrcode"></i></a>*/
										}
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
											<Link to="/free-tools/qr-code-generator">QRCode Generator</Link>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Easily create multiple static QR Codes, integrated into your application, with choices of branded or black and white QR Codes.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="fas fa-barcode"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Barcode Generator</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Easily create multiple static Bar Codes, integrated into your application, with choices of branded.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i class="fas fa-phone-slash"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Phone Number Validation</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Expand your contact database and validate phone numbers with our free tool.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="far fa-building"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Carrier Lookup by Phone Number</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Extract useful information about phone numbers with the carrier lookup tool.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="fas fa-link"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Shorten Link</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Shorten your URL so it’s ready to be shared everywhere.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="fas fa-lock"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>URL Encoder & Decoder</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">URL encode or URL decode a string that you can use to send text messages via API.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="fas fa-key"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Secure Password Generator</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Instantly generate strong passwords for your accounts with the password generator.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="far fa-clock"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Unix Timestamp Converter</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Use this converter to create a string to be used in your app to schedule text messages.</p>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="fas fa-voicemail"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Validate Email Addresses</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Validate email addresses and extract email server information using this free tool.</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 col-lg-6 col-sm-12">
									<div className="free-tool-media">
										<div className="float-left free-tool-media-left">
											<a className="fa-wrap fa-wrap-2x" href="#">
												<i className="far fa-building"></i></a>
										</div>
										<div className="free-tool-media-body">
											<h5 className="title text-info media-heading">
												<a>Billing Address Validation Tool</a>
											</h5>
											<p className="text-padding-right-6 freetool-leading">Extract useful information about phone numbers with the carrier lookup tool.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>
			</React.Fragment>
		)
	}
}
export default FreeTools;