import React, {Component} from 'react'
import { translate, Trans } from "react-i18next";
import {Helmet} from "react-helmet"
import TermsBreadcrumbsSection from '../components/TermsBreadcrumbsSection'

class Terms extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
				<Helmet>
					{/*facebook*/}
					<title> {"TopReal | Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"} </title>
					<meta property="og:title" content="TopReal | Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam" />
					<meta property="og:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta property="og:image" content="http://topreal.vn/images/logo.svg"/>
					<meta property="og:url" content="http://topreal.vn"/>
					{/*Twtiter*/}
					<meta name="twitter:title" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta name="twitter:description" content="Trang thông tin mua bán bất động sản, nhà đất uy tín tại Việt Nam"/>
					<meta name="twitter:image" content="http://topreal.vn/images/logo.svg"/>
					<meta name="twitter:card" content="summary_large_image"/>
				</Helmet>
				<TermsBreadcrumbsSection />
				<div className="agents">
					<div className="container">
						<div className="row">
							<div className="col-12">
								<p style={{textAlign: "justify"}} >
									<a href="http://topreal.vn">Topreal.vn</a> là web site thuộc sở hữu và vận hành bởi công ty <a href="http://toplabs.io" target="_blank"> TopLabs </a>. 
									Đây là chính sách bảo mật trình bày cách thức chúng tôi thu thập, sử dụng, xử lý và bảo vệ dữ liệu cá nhân mà bạn cung cấp trong quá trình sử dụng các dịch vụ của TopReal thông qua website hoặc ứng dụng điện thoại.
									Dữ liệu cá nhân có nghĩa là dữ liệu có thể trình bày đúng hoặc không về một chủ thể xác định nào đó. Các dữ liệu thu thập không giới hạn trên website và ưng dụng di động.
									Khi vào website này, bạn đồng ý và thoả thuận về việc thu thập, sử dụng và xử lý dữ liệu cá nhân của bạn theo chính bảo mật hoặc/và điều khoản sử dụng của Site. 
									Nếu bạn không đồng ý với các điều khoản sau, vui lòng thoát khỏi Site ngay lập tức.
								</p>
								
								<div>
									<h5>1. Thu thập dữ liệu</h5>
								</div>
								<div>
									<h5>2. Sử dụng dữ liệu</h5>
								</div>
								<div>
									<h5>3. Chia sẽ dữ </h5>
								</div>
								<div>
									<h5>3. Bảo vệ dũ liệu </h5>
								</div>
								<div>
									<h5>3. Liên kết với Site khác</h5>
								</div>
								<div>
									<h5>3. Đăng ký thành viên</h5>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}
export default Terms;