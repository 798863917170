import React, {Component} from 'react'

class SearchParamSelector extends Component{
	constructor(props) {
    	super(props)
    	if (props.selected) {
    		this.state = { value: props.selected }
    	} else {
    		this.state = { value: -1}
    	}
    	this.handleOnChange = this.handleOnChange.bind(this)
  	}
  	handleOnChange(event) {  	   
	   this.setState({value: event.target.value})
	   if (typeof (this.props.onChange)  === 'function')
	   		this.props.onChange(event.target.value)
  	}
	render () {
		const {classes} = this.props 
		return (	
			<select className={classes} onChange={this.handleOnChange} value={this.props.selected}>
				{this.props.items.map((item,idx) => <option key= {idx} value={item.id} >{item.name}</option>)}
			</select>
		)
	}
} 
export default SearchParamSelector