import React, { Component } from 'react'
import { translate, Trans } from "react-i18next";

import SearchParamSelector from './SearchParamSelector'


class SearchBoxHome extends Component {
	constructor(props) {
    	super(props)
        this.handleSubmit = this.handleSubmit.bind(this)

	}
	handleSubmit(event){

		//
		event.preventDefault()

  	}
	render (){
		const {t} = this.props
		return (
			<div className="home_search_box">
				<div className='container'>
					<div className='row'>
						<div className='col'>

							<div className="home_search_box_outer">
								<div className="home_search_box_inner">
									<div className="home_search_box_title text-center">
										<div className="home_search_box_title_inner">
											<div className="home_search_box_title_icon d-flex flex-column align-items-center justify-content-center"><img src='images/search.png' alt=''></img></div>
											<span>{t('search_your_home')}</span>
										</div>
									</div>
									
									<form className="home_search_form" onSubmit={this.handleSubmit}>
										<div className="home_search_box_container">
											<ul className="home_dropdown_row clearfix">
												<li className="home_dropdown_item home_dropdown_item_5">
													<div className="home_dropdown_item_title">{t('methods')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_5">
													<div className="home_dropdown_item_title">{t('property_type')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_5">
													<div className="home_dropdown_item_title">{t('city')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_5">
													<div className="home_dropdown_item_title">{t('district')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_5">
													<div className="home_dropdown_item_title">{t('ward')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
											</ul>
										</div>

										<div className="home_search_box_container">
											<ul className="home_dropdown_row clearfix">
												<li className="home_dropdown_item home_dropdown_item_6">
													<div className="home_dropdown_item_title">{t('bedrooms_no')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_6">
													<div className="home_dropdown_item_title">{t('bathrooms_no')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_6">
													<div className="home_dropdown_item_title">{t('min_price')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_6">
													<div className="home_dropdown_item_title">{t('max_price')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_6">
													<div className="home_dropdown_item_title">{t('min_sq ft')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item home_dropdown_item_6">
													<div className="home_dropdown_item_title">{t('max_sq_ft')}</div>
													<SearchParamSelector classes={"home_dropdown_item_select"} items={[]} />
												</li>
												<li className="home_dropdown_item">
													<div className="home_search_button">
														<input value={t('search')} type='submit' className="home_search_submit_button"></input>
													</div>
												</li>
											</ul>
										</div>
									</form>
								</div>
							</div>

						</div>
					</div>
				</div>		
			</div>
		)
	}
}

export default translate("translations")(SearchBoxHome)

///<Link className='btn search_submit_button' to='/listings'>search</Link>
///<input value='search' type='submit' className='search_submit_button'></input>
/*
<div className={style.search_arrow_box}>
										<div className={style.search_arrow_box_inner}>
											<div className={`${style.search_arrow_circle} d-flex flex-column align-items-center justify-content-center`}><span>{t('search_it_here')}</span></div>
											<img src='images/search_arrow.png' alt='' />
										</div>
									</div>
*/