import React from 'react'
import Section from '../../components/Section'

const NotFound = ({props}) => (
	<main role="main">
		<Section title="Page Not Found" subTitle="Please go to home pages."/>
		<section className="section-notfound">
			<div className="container">
				<header role="title-page" className="text-center">
					<h2>The website is underconstruction.</h2>
					<h4></h4>
				</header>
				<div className="clearfix"></div>
			</div>
		</section>
	</main>
)
export default NotFound;