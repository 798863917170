import React from 'react'
import { translate } from "react-i18next"
import SearchBoxHome from './SearchBoxHome'
///https://css-tricks.com/snippets/css/a-guide-to-flexbox/#flexbox-background
const HomeSearchBoxSection = ({title,subTitle}) => (
	<section className="section-home-search-box">
		<div className="container">
			<div className="row">
				<div className="col">
					<div className="home_search_box_outer">
						<div className="home_search_box_inner">
							<SearchBoxHome />
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
)
export default translate("translations")(HomeSearchBoxSection);