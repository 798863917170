import React from 'react'
import { translate } from "react-i18next"

function getYear () {
	return new Date().getFullYear()
}
const Footer = ({t, props}) => (
	<footer className="footer-area">
        <div className="container">
            <div className="row">
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <a href="/" className="foo-logo"><img src="images/logo.png" alt="TopLabs Logo"/></a>
                        <p>{t('the_top_labs_is_a_tech_company...')}</p>
                        <div className="footer-social-info">
                            <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a>
                            <a href="#" className="google-plus"><i className="fab fa-google-plus-g"></i></a>
                            <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
                            <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
                            <a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
                        </div>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('account')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/register"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('register')}</a></li>
                                <li><a href="/login"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('login')}</a></li>
                                <li><a href="http://account.topreal.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {"My TopLabs"}</a></li>
                                <li><a href="http://my.topreal.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {"My TopReal"}</a></li>
                                <li><a href="http://my.topauto.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {"My TopAuto"}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('useful_links')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/news"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('news')}</a></li>
                                <li><a href="/jobs"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('jobs')}</a></li>
                                <li><a href="/partners"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('partners')}</a></li>
                                <li><a href="/about"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {t('about_us')}</a></li>
                                <li><a href="/sitemap"><i className="fa fa-angle-double-right" aria-hidden="true"></i> {"Sitemap"}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('investment_channel')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Dự án đất nền</a></li>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Căn hộ chung cư</a></li>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Khu nghỉ dưỡng</a></li>
                                <li><a href="/coming-soon"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Chia sẻ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('linked_sites')}</h6>
                        <nav className="footer-widget-nav">
                       
                            <ul>
                                <li><a href="http://topauto.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> TopAuto</a></li>
                                <li><a href="http://topreal.vn" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> TopReal</a></li>
                                <li><a href="http://zetgoo.com" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Zetgoo</a></li>
                                <li><a href="http://thinktree.zetgoo.com" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Tiên Tri</a></li>
                                <li><a href="http://skeleton.zetgoo.com" target="_blank"><i className="fa fa-angle-double-right" aria-hidden="true"></i> Công cụ</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3">
                    <div className="footer-widget">
                        <h6 className="widget-title">{t('contact_info')}</h6>
                        <nav className="footer-widget-nav">
                            <ul>
                                <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/placeholder.svg" alt=""></img></div></div>
                                <div className="contact_info_text">{t('topauto_address')}</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+8490 866 77 96</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/phone-call.svg" alt=""></img></div></div>
                                <div className="contact_info_text">+8498 243 71 72</div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/message.svg" alt=""></img></div></div>
                                <div className="contact_info_text"><a href="mailto:contact.toplabs@gmail.com?Subject=Hello" target="_top">contact.toplabs@gmail.com</a></div>
                            </li>
                            <li className="contact_info_item d-flex flex-row">
                                <div><div className="contact_info_icon"><img src="images/planet-earth.svg" alt=""></img></div></div>
                                <div className="contact_info_text"><a href="http://toplabs.io">http://toplabs.io</a></div>
                            </li>
                            </ul>
                        </nav>
                    </div>
                </div>

            </div>
        </div>
        <div className="copywrite-area">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-sm-6">
                        <p className="copywrite-text">
Bản quyền  ©  {getYear()} Tất cả được bảo vệ | Phát triển bởi <i className="far fa-heart" aria-hidden="true"></i> <a href="http://toplabs.io" target="_blank">TopLabs</a></p>
                    </div>
                    <div className="col-12 col-sm-6">
                        <nav className="footer-nav">
                            <ul>
                                <li><a href="/">{t('home')}</a></li>
                                <li><a href="/terms">{t('terms')}</a></li>
                                <li><a href="/privacy">{t('privacy')}</a></li>
                                <li><a href="/contact">{t('contact')}</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </footer>
)
export default translate("translations")(Footer)