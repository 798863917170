import React, {Component} from 'react'
import HomeSearchBoxSection from '../components/HomeSearchBoxSection'
import NewsLetter from '../components/NewsLetter'
import Carousel from 'react-bootstrap/Carousel'

// import ReactCountdownClock from 'react-countdown-clock'
class Home extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		let nextIcn = <span aria-hidden="true" className="carousel-control-next-icon" />
		return (
			<React.Fragment>
				<main role="main">
					<Carousel nextIcon={nextIcn}>
						<Carousel.Item>
							<img className="d-block w-100" src="/images/home_slider_bcg.jpg" alt="TopReal"/>
							<Carousel.Caption>
								<h3>Second slide label</h3>
								<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
							</Carousel.Caption>
						</Carousel.Item>
						<Carousel.Item>
							<img className="d-block w-100" src="/images/topauto_banner1.jpg" alt="TopAuto" />
							<Carousel.Caption> <h3>Third slide label</h3>
							<p>Praesent commodo cursus magna, vel scelerisque nisl consectetur.</p>
							</Carousel.Caption>
						</Carousel.Item>
					</Carousel>
					<NewsLetter />
				</main>
			</React.Fragment>
		)
	}
}
export default Home;