import React, {Component} from 'react'
import { Form, Button } from 'react-bootstrap';
import Section from '../components/Section'

class Contact extends Component {
	constructor(props) {
		super(props)
	}
	render () {
		return (
			<React.Fragment>
                <main role="main">
				    <Section title="Getting Started with ZETGOO's SKELETON" subTitle="Frequency Answering & Question that importance for you."/>
                    <section>
                    </section>
                </main>
			</React.Fragment>
		)
	}
}
export default Contact;
